import React, { Fragment } from "react";
import { Layout, BackTop } from "antd";
const { Header, Content, Footer } = Layout;

require("../style/main.css");

export default function Main() {
  return (
    <Fragment>
      <Layout
        className="overwriteAntdStyle"
        style={{ fontFamily: "Montserrat", height: "auto", minHeight: "100%" }}
      >
        <Header></Header>
        <Content style={{ backgroundColor: "white" }}>
          <div className="paddingContent"></div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Version{process.env.REACT_APP_VERSION} <br /> Copyright @2023
          Byvidé.com
          <BackTop />
        </Footer>
      </Layout>
    </Fragment>
  );
}
