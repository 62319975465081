import React, { Fragment } from "react";
import { Layout } from "antd";
const { Content } = Layout;

require("../style/main.css");

export default function Invitation() {
  return (
    <Fragment>
      <Layout
        className="overwriteAntdStyle"
        style={{ fontFamily: "Montserrat", height: "auto", minHeight: "100%" }}
      >
        <Content style={{ backgroundColor: "cream" }}>
          <div className="paddingContent">
            <h3>Stanly & Dessy</h3>
            <h3>19.01.25</h3>
          </div>
        </Content>
      </Layout>
    </Fragment>
  );
}
